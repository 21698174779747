<template>
  <v-container class="nopadding">
    <v-toolbar fixed
      class="toolbar-noshadow toolbar-page-name">
      <router-link
        v-if="$route.name !== 'referrallist'"
        class="pr-4"
        :to="{ name: 'referrallist' }">
        <v-icon>mdi-arrow-left</v-icon>
      </router-link>
      <v-toolbar-title><strong>{{ pageName }}</strong></v-toolbar-title>
    </v-toolbar>
    <v-container class="page-container">
      <router-view></router-view>
    </v-container>
  </v-container>
</template>
<script>

  export default {
    name: 'Referral',
    computed: {
      pageName() {
        let name = 'Referral'
        switch (this.$route.name) {
          case 'viewreferral':
            name = 'Business Detail'
            break;
          case 'editreferral':
            name = 'Edit Referral'
            break;
          case 'addreferral':
            name = 'Add Referral'
            break;
          default:
            name = 'Referral'
            break;
        }
        return name;
      }
    }
  }
</script>
<style lang="scss" scoped>
  .nopadding {
    padding: 0;
    @media screen and (max-width: 768px){
      //padding-bottom: 80px;
    }
  }
  .page-container {
    padding-top: 16px;
    @media screen and (max-width: 768px){
      padding-bottom: 10px;
    }
  }
  .add-btn {
    bottom: 16px!important;
  }
  ::v-deep {
    a{
      text-decoration: none;
    }
    @media screen and (max-width: 768px){
      .toolbar-page-name {
        box-shadow: none!important;
        border-bottom: 1px solid lightgray;
      }
    }
  }
</style>
